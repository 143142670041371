<template>
  <div>
    <v-tabs v-model="tab" background-color="transparent" slider-color="white">
      <v-tab v-for="(tab, i) in tabs" :key="i" class="mr-3">{{
        tab.title
      }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" class="transparent" style="min-height: 200px">
      <v-tab-item> 
          <config-script />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
export default {
    components: {
        configScript: () => import("../components/configScript.vue")
    },
  data() {
    return {
      tab: 0,
      tabs: [{ title: "Скрипт разговора" }],
    };
  },
};
</script>